import { AppProps } from "next/app";
import "../css/tailwind.css";
import * as Sentry from "@sentry/node";
import { RewriteFrames } from "@sentry/integrations";
import getConfig from "next/config";
import "draft-js/dist/Draft.css";

type RuntimeConfig = {
  serverRuntimeConfig: { rootDir: string };
};

// Sentry wird für das Error-Messaging verwendet – Nicht zwingend notwendig
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig() as RuntimeConfig | undefined;
  const distDir = `${config?.serverRuntimeConfig.rootDir}/.next`;
  if (config) {
    Sentry.init({
      enabled: process.env.NODE_ENV === "production",
      integrations: [
        new RewriteFrames({
          iteratee: (frame) => {
            frame.filename = frame.filename?.replace(distDir, "app:///_next");
            return frame;
          },
        }),
      ],
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    });
  }
}
export default function App({
  Component,
  pageProps,
  err,
}: AppProps & { err: unknown }) {
  return <Component {...pageProps} err={err} />;
}
